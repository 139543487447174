// Copied from
// ClientApp/src/app/feature-modules/collection-calendar/components/collection-calendar/collection-calendar.component.ts
// TODO: move to ROVA sdk or something?

export function getGarbageTypeIcon(garbageTypeCode: string) {
  switch (garbageTypeCode) {
    case 'REST':
    case 'RESTAFVAL':
      return 'restafval';
    case 'GFT':
      return 'gft';
    case 'GFTE':
      return 'gfte';
    case 'PAPIER':
    case 'PD':
      return 'papier-karton';
    case 'LOS_PAPIER':
      return 'los-papier';
    case 'PLASTICPLUS':
    case 'PLASTIC':
    case 'PMD':
    case 'MD':
      return 'pmd';
    case 'PMD_IN_ZAKKEN':
      return 'pmd-in-zakken';
    case 'GROF':
      return 'grof-tuinafval';
    case 'KRINGLOOP':
      return 'kringloop';
    case 'KCA':
      return 'klein-chemisch-afval';
    case 'GLAS':
      return 'glas';
    case 'TAKKEN':
      return 'plant';
    case 'MEDISCH_AFVAL':
      return 'medisch-afval';
    case 'RSM':
      return 'medisch-afval';
    default:
      return garbageTypeCode.toLowerCase();
  }
}
