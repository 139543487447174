import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DepositsCurrentYearGuard } from '../insight/guards/deposits-current-year.guard';
import { CheckAuthGuard } from '../auth/guards/checkAuth.guard';
import { WasteCalendarGuard, WasteCalendarYearGuard } from './guards';
import { WasteCalendarComponent } from './containers/waste-calendar/waste-calendar.component';
import { DepositsGuard } from '../insight/guards';
import { NearestGripLocationGuard } from '../notifications/guards';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: WasteCalendarComponent,
    canActivate: [
      CheckAuthGuard,
      WasteCalendarGuard(8),
      WasteCalendarYearGuard, // TODO: Move to waste calendar page routing
      NearestGripLocationGuard,
      DepositsGuard,
      DepositsCurrentYearGuard,
    ],
    runGuardsAndResolvers: 'paramsOrQueryParamsChange',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WasteCalendarRoutingModule {}
