<article class="waste-calendar">
  <app-header [address]="address$() || undefined" [subtitle]="'mijn-rova-inzamel-kalender-titel' | fromDictionary">
    <div class="header-cta-card type--tip card text-max-width-lg py-lg-4 px-lg-5 d-none d-md-flex" *ngIf="tip$()">
      <span class="chip chip--md me-3">
        <fstr-icon anchor="icon-light-bulb" class="header-cta-card__icon"></fstr-icon>
      </span>
      <div>
        <p class="mb-0">
          {{ tip$().intro | truncate: 200 }}
        </p>
        <a [routerLink]="tip$().url" class="button button--primary button--xs button-link">
          Lees verder
          <fstr-icon anchor="icon-arrow-right" class="ms-2"></fstr-icon>
        </a>
      </div>
    </div>
  </app-header>

  <main class="waste-calendar__main section-inset-y bg-space-gray-200" *ngIf="calendarItems$() as calendarItems">
    <div class="container waste-calendar__main__container">
      <app-alert
        class="waste-calendar__alert"
        [status]="'warning'"
        [message]="'inzamel-kalender-geen-gegevens-tekst' | fromDictionary"
        *ngIf="!calendarItems.length"
      ></app-alert>

      <section class="card wastecalendar__section" [class.no--data]="!calendarItems.length">
        <div class="d-flex flex-md-row flex-column flex-gap-2">
          <div class="flex-grow-1">
            <h2 class="h6 font-secondary text-secondary mb-0">{{ 'inzamelkalender-titel' | fromDictionary }}</h2>
            <!--<span class="text-muted d-inline-block w-100">{{ 'filter-op-maand-label' | fromDictionary }}</span>-->
          </div>
          <!--<span class="flex-grow-1"></span>-->
          <!-- Month select -->
          <div class="d-flex align-items-center me-3 mt-md-0 mt-3">
            <button
              title="vorige maand"
              class="button button--subtle button-outline button--secondary button-icon me-3"
              (click)="prevMonth()"
              [disabled]="!hasPrev$()"
            >
              <fstr-icon anchor="icon-chevron-left"></fstr-icon>
            </button>
            <mat-form-field [subscriptSizing]="'dynamic'" appearance="outline" class="width--unset me-3 flex-grow-1">
              <mat-label>Selecteer maand</mat-label>
              <select matNativeControl [value]="firstDayOfMonth$().toUTCString()" (change)="setMonth(monthSelect.value)" #monthSelect>
                <option
                  *ngFor="let m of selectableMonths$()"
                  [selected]="m.toUTCString() === firstDayOfMonth$().toUTCString()"
                  [value]="m.toUTCString()"
                >
                  {{ m | date: 'MMMM yyyy' }}
                </option>
              </select>
            </mat-form-field>
            <button
              title="volgende maand"
              class="button button--subtle button-outline button--secondary button-icon"
              (click)="nextMonth()"
              [disabled]="!hasNext$()"
            >
              <fstr-icon anchor="icon-chevron-right"></fstr-icon>
            </button>
          </div>
          <!-- Download button -->
          <!-- [queryParams]="downloadCalendarParams$()"
          [routerLink]="'/api/waste-calendar/year/pdf'" -->
          @if (calendarItems.length) {
            <app-calendar-download-button [availableYears]="availableYears$()" [address]="address$()"></app-calendar-download-button>
          }
        </div>
        <!-- <h3 class="h6 font-secondary text-secondary">{{ firstDayOfMonth$() | date : 'MMMM yyyy' }}</h3> -->

        <app-waste-calendar-grid [date]="firstDayOfMonth$()" [items]="calendarItems$()"></app-waste-calendar-grid>

        @if (calendarItems.length) {
          <app-calendar-download-button
            [cssClass]="'d-md-none d-flex w-100 mt-3'"
            [availableYears]="availableYears$()"
            [address]="address$()"
          ></app-calendar-download-button>
        }

        <!-- <button class="button button--subtle button-outline button--secondary d-md-none d-block w-100 mt-3" (click)="downloadCalendar()">
          <span>{{ 'download-inzamelkalender-knoptekst' | fromDictionary }}</span>
          <fstr-icon anchor="icon-download"></fstr-icon>
        </button> -->
      </section>

      <section class="card section-outset-t" [class.bg-warning]="!calendarItems.length" *ngIf="calendarItems.length">
        <header>
          <h2 class="h6 font-secondary text-secondary">
            {{ 'eerstvolgende-inzamelmomenten-titel' | fromDictionary }}
          </h2>
          <span class="text-muted mb-4 d-inline-block w-100">
            {{ 'het-is-vandaag-prefix' | fromDictionary }} {{ today | date: 'd MMMM yyyy' }}
          </span>
        </header>

        <div class="row">
          <div class="col-md-12">
            <ul class="waste-calendar-item-list waste-calendar-item-list--full-width">
              <li class="waste-calendar-item-list__item" *ngFor="let item of upcoming$()">
                <app-waste-calendar-item [item]="item"></app-waste-calendar-item>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  </main>
</article>
