import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromNotifications from '../../../notifications/store';
import * as fromWasteCalendarItems from '../reducers/waste-calendar-items.reducer';
import { WasteCalendarDate } from '../../models';

export const getWasteCalendarItemsState = createSelector(
  fromFeature.getWasteCalendarState,
  (state: fromFeature.WasteCalendarState) => state.wasteCalendarItems
);

export const getWasteCalendarItemEntities = createSelector(
  getWasteCalendarItemsState,
  fromWasteCalendarItems.getWasteCalendarItemsEntities
);

export const getAllWasteCalendarItems = createSelector(getWasteCalendarItemEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]);
});

export const getCombinedWasteCalendarItems = createSelector(
  getAllWasteCalendarItems,
  fromNotifications.getNearestGripLocationSchedule,
  (items, grip) => {
    const sortedItems = [...items, ...(grip || [])].filter(a => a) as WasteCalendarDate[];
    sortedItems.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      if (dateA === dateB) {
        return items.indexOf(a) - items.indexOf(b);
      }
      return dateA > dateB ? 1 : -1;
    });
    return sortedItems;
  }
);

export const hasWasteCalendarItems = createSelector(getWasteCalendarItemEntities, items => {
  return Object.keys(items)?.length > 0;
});

export const getOrderedWasteCalendarItems = createSelector(getCombinedWasteCalendarItems, (Pages: WasteCalendarDate[]) => {
  const orderedPages = Pages.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1));
  return orderedPages;
});

export const getWasteCalendarItemsLoaded = createSelector(getWasteCalendarItemsState, fromWasteCalendarItems.getWasteCalendarItemsLoaded);
export const getWasteCalendarItemsLoading = createSelector(getWasteCalendarItemsState, fromWasteCalendarItems.getWasteCalendarItemsLoading);
export const getWasteCalendarYearItemsLoaded = createSelector(
  getWasteCalendarItemsState,
  fromWasteCalendarItems.getWasteCalendarYearItemsLoaded
);
export const getWasteCalendarYearItemsLoading = createSelector(
  getWasteCalendarItemsState,
  fromWasteCalendarItems.getWasteCalendarYearItemsLoading
);
export const getWasteCalendarItemsError = createSelector(getWasteCalendarItemsState, fromWasteCalendarItems.getWasteCalendarItemsError);
