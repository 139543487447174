import { AfterViewInit, ChangeDetectionStrategy, Component, computed, effect, inject, signal } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Store } from '@ngrx/store';
import { addMonths, addDays, getMonth, getYear, differenceInMonths } from 'date-fns';

import * as fromAddresses from '../../../addresses/store';
import * as fromCalendar from '../../../waste-calendar/store';
import * as fromAuth from '../../../auth/store';
import * as fromInsight from '../../../insight/store';
import { asapScheduler } from 'rxjs';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { SetTags, SetTitle } from 'src/app/store';
import * as fromTips from '../../../tips/store';
@Component({
  selector: 'app-waste-calendar',
  templateUrl: './waste-calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WasteCalendarComponent implements AfterViewInit {
  lang = inject(LanguageService);
  private Store = inject(Store);
  private dict = new FromDictionaryPipe(this.lang);

  today = new Date();

  tip$ = this.Store.selectSignal(fromTips.getRandomWastCalendarTip);

  selectedDate$ = signal(new Date());
  firstDayOfMonth$ = computed(() => {
    return new Date(getYear(this.selectedDate$()), getMonth(this.selectedDate$()), 1);
  });

  address$ = this.Store.selectSignal(fromAddresses.getAddress);
  calendarItems$ = this.Store.selectSignal(fromCalendar.getOrderedWasteCalendarItems);
  isSignedIn$ = this.Store.selectSignal(fromAuth.getAuthSignedIn);
  yearlyDeposits$ = this.Store.selectSignal(fromInsight.getCurrentYearDepositsAmount);
  monthlyDeposits$ = this.Store.selectSignal(fromInsight.getCurrentMonthDepositsAmount);

  upcoming$ = computed(() =>
    this.calendarItems$()
      .filter(a => addDays(new Date(a.date), 1) >= new Date())
      .slice(0, 6)
  );
  selectableMonths$ = computed(() =>
    [...new Set(this.calendarItems$().map(a => new Date(getYear(new Date(a.date)), getMonth(new Date(a.date)), 1).toUTCString()))].map(
      a => new Date(a)
    )
  );
  availableYears$ = computed(() => [...new Set(this.calendarItems$().map(a => getYear(new Date(a.date))))]);

  hasNext$ = computed(
    () =>
      this.selectableMonths$()
        .map(a => a.toUTCString())
        .indexOf(this.firstDayOfMonth$().toUTCString()) <
      this.selectableMonths$().length - 1
  );

  hasPrev$ = computed(
    () =>
      this.selectableMonths$()
        .map(a => a.toUTCString())
        .indexOf(this.firstDayOfMonth$().toUTCString()) > 0
  );

  metafields = effect(() => {
    asapScheduler.schedule(() =>
      this.Store.dispatch(
        SetTags({
          properties: {
            'og:title': `${this.dict.transform('mijn-rova-inzamel-kalender-titel')} ${this.dict.transform('meta-titel-suffix')}`,
          },
        })
      )
    );

    asapScheduler.schedule(() => {
      this.Store.dispatch(
        SetTitle({ title: `${this.dict.transform('mijn-rova-inzamel-kalender-titel')} - ${this.dict.transform('meta-titel-suffix')}` })
      );
    });
  });

  ngAfterViewInit(): void {
    this.Store.dispatch(fromTips.LoadTips());
  }

  nextMonth() {
    this.selectedDate$.set(addMonths(this.selectedDate$(), 1));
  }

  prevMonth() {
    this.selectedDate$.set(addMonths(this.selectedDate$(), -1));
  }

  setMonth(e: string) {
    const date = new Date(e);
    var d = new Date(getYear(date), getMonth(date), 1);
    this.selectedDate$.set(d);
  }
}
